import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      name: "home",
      path: "/",
      component: () => import("./views/Home.vue"),
    },
    {
      name: "delta-billing",
      path: "/delta/billing",
      component: () => import("./views/Delta/Billing.vue"),
      meta: {
        allowed: "deltaTag/viewAdminBilling"
      }
    },
    {
      name: "configure-actions-manage",
      path: "/configure/actions/manage",
      component: () => import("./views/Configure/Actions/Manage.vue"),
      meta: {
        allowed: "analytics/actionsManage"
      }
    },
    {
      name: "trends-topic-management",
      path: "/trends/topic",
      component: () => import("./views/Trends/Cluster.vue"),
      meta: {
        allowed: "trends/admin"
      }
    },
    {
      name: "trends-domain-management",
      path: "/trends/domain",
      component: () => import("./views/Trends/Domain.vue"),
      meta: {
        allowed: "trends/admin"
      }
    },
    {
      name: "firmographic-search",
      path: "/firmographic/search",
      component: () => import("./views/Firmographic/Search"),
      meta: {
        allowed: "firmographic/view",
      },
    },
    {
      name: "firmographic-detail",
      path: "/firmographic/detail/:uuid",
      component: () => import("./views/Firmographic/Detail"),
      meta: {
        allowed: "firmographic/view",
      },
    },
    {
      name: "configure-firmographic-groups",
      path: "/configure/firmographic/groups",
      component: () => import("./views/Configure/Firmographic/Groups.vue"),
      meta: {
        allowed: "client/alphaAnalyticsView"
      }
    },
    {
      name: "trends-url-management",
      path: "/trends/url/:clientUuid?",
      component: () => import("./views/Trends/Url.vue"),
      meta: {
        allowed: "topics/urlAdmin"
      }
    },
    {
      name: "trends-booking-management",
      path: "/trends/booking/:clientUuid?",
      component: () => import("./views/Trends/Booking.vue"),
      meta: {
        allowed: "topics/bookingAdmin"
      }
    },
  ]
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
});

router.beforeEach((to, from, next) => {
  if (typeof dataLayer !== "undefined" && Array.isArray(dataLayer)) {
    dataLayer.push({
      'event': `pageView`,
      'fullPath': to.fullPath,
      'path': to.path,
      'name': to.name,
    });
  }
  next();
});

export default router;