import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import {store} from "./store";
import vuetify from "./plugins/vuetify";
import { fAllowed } from "@/core/connect/mixin";
import * as Sentry from "@sentry/vue";
import * as Integrations from "@sentry/integrations";
import Notifications from "vue-notification";
import Fundavue from '@fundamentalmedia/fundavue';

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://dac8400ba30f48c2bccd251c0486e26c@o137032.ingest.sentry.io/5910561",
    environment: 'production',
    integrations(integrations) {
      integrations.push(
        new Integrations.Vue({Vue, attachProps: true, console: true})
      );
      return integrations;
    }
  });
}

// Loaders
Vue.component(
    "loader-fullpage",
    require("./components/Loader/Fullpage").default
);

const fundavueOptions = {
    theme: "sAlphix",
}
Vue.use(Fundavue, fundavueOptions);
Vue.use(Notifications);

Vue.mixin({
            methods: {
              fAllowed: fAllowed
            },
          });

new Vue({
          vuetify,
          el: "#app",
          router,
          store,
          render: h => h(App)
        });
