<template>
  <div
    class="sLoader"
    :style="style"
  >
    <div>
      <div>
        <div /><div /><div />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageLoader",
  props: {
    height: {
      type: Number,
      default: 200
    }
  },
  computed: {
    style() {
      const style = {};
      if (this.height) {
        style.height = `${this.height}px`
      }
      return style;
    }
  }
};
</script>

<style scoped lang="scss">
@keyframes sLoader-1 {
  0% { top: 40px; height: 120px }
  50% { top: 60px; height: 80px }
  100% { top: 60px; height: 80px }
}
@keyframes sLoader-2 {
  0% { top: 45px; height: 110px }
  50% { top: 60px; height: 80px }
  100% { top: 60px; height: 80px }
}
@keyframes sLoader-3 {
  0% { top: 50px; height: 100px }
  50% { top: 60px; height: 80px }
  100% { top: 60px; height: 80px }
}
.sLoader {
  margin: 0 auto;
  width: 200px;
  position: relative;
  > div {
    height: 200px;
    width: 200px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    > div {
      position: relative;
      height: 200px;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0;
      div {
        position: absolute;
        width: 20px;
        box-sizing: content-box;
        &:nth-child(1) {
          left: 40px;
          background: #ff7f00;
          animation: sLoader-1 1.4925373134328357s cubic-bezier(0, 0.5, 0.5, 1) infinite;
          animation-delay: -0.29850746268656714s;
        }

        &:nth-child(2) {
          left: 90px;
          background: #979797;
          animation: sLoader-2 1.4925373134328357s cubic-bezier(0, 0.5, 0.5, 1) infinite;
          animation-delay: -0.14925373134328357s;
        }

        &:nth-child(3) {
          left: 140px;
          background: #4ce1a8;
          animation: sLoader-3 1.4925373134328357s cubic-bezier(0, 0.5, 0.5, 1) infinite;
        }
      }
    }
  }
}
</style>