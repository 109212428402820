<template>
  <v-app class="app">
    <page-loader v-if="loading" />
    <v-card
      v-else-if="restrictedPage"
      max-width="400"
      class="ma-auto my-10"
      rounded="lg"
    >
      <v-card-title>
        Restricted Area
      </v-card-title>
      <v-card-text>
        You are not allowed to enter this area at present. Please contact your company administrator to be granted
        additional access.
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        Code: {{ restrictedPage }}
      </v-card-actions>
    </v-card>
    <div v-else>
      <page-header />
      <div class="sMain d-inline-flex">
        <page-header-left-menu />
        <div class="sMainColumn">
          <v-main>
            <transition
              name="slide-fade"
              mode="out-in"
            >
              <router-view
                :key="$route.fullPath"
              />
            </transition>
          </v-main>
        </div>
      </div>
      <notifications />
    </div>
  </v-app>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";
import PageHeader from "./components/Page/Header";
import PageLoader from "./components/Page/Loader";
import PageHeaderLeftMenu from "@/components/Page/Header/LeftMenu";

export default {
  components: {
    PageHeaderLeftMenu,
    PageLoader,
    PageHeader,
  },
  data() {
    return {
      restrictedPage: null,
      clientUuid: null
    }
  },
  computed: {
    ...mapGetters("alphix", ["getAllowed", "getUser"]),
    loading() {
      return !this.getAllowed || !this.getUser || this.restrictedPage === null;
    }
  },
  watch: {
    $route() {
      this.restrictedPage = null;
      this.checkRestricted();
    },
    getAllowed() {
      this.restrictedPage = null;
      this.checkRestricted();
    }
  },
  created() {
    this.lookupUser();
    this.lookupAllowed();
  },
  methods: {
    ...mapActions("alphix", ["lookupUser", "lookupAllowed"]),
    checkRestricted() {
      if (this.getAllowed) {
        if (!this.$route?.meta?.allowed) {
          this.restrictedPage = false;
        } else {
          let access = this.$route?.meta?.allowed;

          this.restrictedPage = !this.fAllowed(access) ? access : false;
        }
      }
    }
  }
}
</script>

<style type="text/css" lang="scss">
@import "scss/fundamental-variables.scss";

body,
html {
  margin: 0;
  padding: 0;
  font-family: $fontFamily;
  background-color: $colorLightest;

  .number-font-family {
    font-family: $number-font-family !important;
  }
}

.app {
  min-height: 100vh;
  background-color: $colorLightest !important;
}
</style>

<style type="text/css" lang="sass">
@import "scss/fundamental-variables.scss"

.sMain
  height: calc(100vh - 90px)
  width: 100%

  .sMainColumn
    max-width: 1650px
    padding: 0 20px
    margin: 0 auto
    display: flex
    flex-grow: 1
    flex-direction: column

/** TRANSITIONS **/
.slide-fade-enter-active, .slide-fade-leave-active
  transition: 0.5s

.slide-fade-enter
  opacity: 0
  transform: translateX(-10px)

.slide-fade-leave-to
  opacity: 0
  transform: translateX(10px)

.slide-down-enter-active,
.slide-down-leave-active
  transition: 0.5s

.slide-down-enter
  opacity: 0
  transform: translateY(-10px)

.slide-down-leave-to
  opacity: 0
  transform: translateY(-10px)

.slide-left-enter-active,
.slide-left-leave-active
  transition: 0.1s ease-in

.slide-left-enter,
.slide-left-leave-to
  z-index: 999
  opacity: 0
  transform: translateX(-10px)

/** END TRANSITIONS **/

.ui-alert__body
  min-height: auto

.ui-textbox.is-active:not(.is-disabled)
  .ui-textbox__label-text
    color: $colorImpactDark

.ui-textbox.is-active:not(.is-disabled)
  .ui-textbox__input, .ui-textbox__textarea
    border-bottom: solid 1px $colorImpactDark

.ui-select.is-active:not(.is-disabled)
  .ui-select__label-text
    color: $colorImpactDark

.ui-select.is-active:not(.is-disabled)
  .ui-select__icon-wrapper .ui-icon
    color: $colorImpactDark

.ui-select.is-active:not(.is-disabled)
  .ui-select__display
    border-bottom: solid 1px $colorImpactDark

.ui-button--type-primary.ui-button--color-red
  background-color: $colorImpactDark

.ui-button--type-primary.ui-button--color-red:hover
  background-color: $colorImpact !important

.ui-button--type-primary.ui-button--color-accent
  background-color: $colorDark

.ui-button--type-primary.ui-button--color-accent:hover
  background-color: $colorMediumDark !important

.ui-button--type-primary.ui-button--color-default
  background-color: $colorMedium
  color: white

.ui-button--type-primary.ui-button--color-default:hover
  background-color: $colorMediumLight !important
.v-card.v-sheet
  border-radius: 10px!important
.v-data-table
  border-radius: 10px!important
</style>