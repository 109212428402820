<template>
  <div :style="{ height: height + 'px', position: 'relative' }">
    <div class="loadingBox">
      <div class="loadingBoxText">
        {{ message }}
      </div>
      <v-progress-linear
        indeterminate
        color="#bf1818"
        class="loadingBoxBar"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: [Number, String],
      default: 150
    },
    message: {
      type: String,
      default: "Loading..."
    }
  },
};
</script>

<style lang="sass">
@import "../../scss/fundamental-variables.scss"
.loadingBox
    position: absolute
    top: 50%
    bottom: 50%
    left: 50%
    right: 50%
    text-align: center
    width: 50%
    .loadingBoxText
        color: $colorDark
        width: 50%
        margin-left: -25%
        -webkit-animation: pulsate 2s ease-out
        -webkit-animation-iteration-count: infinite
        opacity: 0.5
    .loadingBoxBar
        width: 50%
        margin-left: -25%
        background-color: $colorLight
        div.ui-progress-linear__progress-bar
            background-color: $colorImpactDark
@-webkit-keyframes pulsate
    0%
        opacity: 0.5
    50%
        opacity: 1.0
    100%
        opacity: 0.5
</style>
