var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('div',{staticClass:"sPageLeftMenu"},[_c('page-header-left-menu-group',{staticClass:"sSectionGroup",attrs:{"section":{
        title: 'Delta Tag',
        links: [
          {title: 'Annual Billing Overview', routeName: 'delta-billing'}
        ]
      }}}),_c('page-header-left-menu-group',{staticClass:"sSectionGroup",attrs:{"section":{
        title: 'Trends',
        links: [
          {title: 'Manage Topics', routeName: 'trends-topic-management'},
          {title: 'Manage Domains', routeName: 'trends-domain-management'},
          {title: 'Manage URLs', routeName: 'trends-url-management'},
          {title: 'Manage Bookings', routeName: 'trends-booking-management'},
        ]
      }}}),_c('page-header-left-menu-group',{staticClass:"sSectionGroup",attrs:{"section":{
        title: 'Configure',
        links: [
          {title: 'Master Firmo Groups', routeName: 'configure-firmographic-groups'},
          {title: 'Manage Firms', routeName: 'firmographic-search'},
          {title: 'Actions', routeName: 'configure-actions-manage'}
        ]
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }