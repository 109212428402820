<template>
  <div
    v-if="Object.keys(section).length"

    class="sPageHeaderLeftMenuGroup"
  >
    <div
      class="sTitle"
      :class="{ active: isChildActive(section.links) }"

      @click="() => isExpanded = !isExpanded"
    >
      <v-icon>fal fa-calendar</v-icon>
      <span>{{ section.title }}</span>
      <v-icon>fal {{ isExpanded ? 'fa-angle-up' : 'fa-angle-down' }}</v-icon>
    </div>
    <transition name="slide-down">
      <ul
        v-show="isExpanded"
      >
        <li
          v-for="link of section.links"
          :key="link.title"

          class="sChildTitle"
          :class="{ active: isActive(link) }"

          @click="() => goTo(link)"
        >
          {{ link.title }}
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: "PageHeaderLeftMenuGroup",
  props: {
    section: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isExpanded: true
    }
  },
  methods: {
    isActive(link) {
      return link.routeName === this.$route.name ||
        link?.alternativeRoutes?.includes(this.$route.name);
    },
    isChildActive(links) {
      return !!links.find(l =>
        l.routeName === this.$route.name ||
        l?.alternativeRoutes?.includes(this.$route.name)
      );
    },
    goTo(routeOptions) {
      if (this.$route.name !== routeOptions.routeName) {
        this.$router.push({name: routeOptions.routeName});
      }
    }
  }
}
</script>

<style scoped lang="scss">
.sPageHeaderLeftMenuGroup {
  width: 100%;

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
    }
  }

  .sTitle, li {
    cursor: pointer;
  }

  .sTitle {
    height: 40px;
    display: flex;
    padding-left: 40px;
    padding-right: 12px;
    align-items: center;

    :first-child{
      font-size: 17px;
      padding-right: 12px;
    }
    :last-child {
      margin-left: auto;
    }

    span {
      color: rgba(0, 0, 0, 0.6);
      font-size: 15px;
      letter-spacing: 0;
      line-height: 16px;
    }

    &.active, &:hover {
      background-color: #F0F0F1;
    }
  }

  .sChildTitle {
    height: 40px;
    padding-left: 80px;
    color: rgba(0,0,0,0.6);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    display: flex;
    align-items: center;

    &.active, &:hover {
      color: #FFFFFF;
      background-color: #303133;
    }
  }
}
</style>