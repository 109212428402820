import Vue from "vue";
import Vuex from "vuex";
import alphix from "./store/alphix";
import firmographicGroup from "@/store/firmographicGroup";

Vue.use(Vuex);

const state = {};
const getters = {};
const actions = {};
const mutations = {};

export const store = new Vuex.Store({
  modules: {
    alphix,
    firmographicGroup,
  },
  state,
  getters,
  actions,
  mutations
});
