<template>
  <v-autocomplete
    v-model="currentClientUuid"
    dense
    :disabled="clientList.length === 1"
    flat
    hide-details
    :items="clientList"
    :loading="clientList.length === 1"
    placeholder="Return to Alphix"
    solo
  />
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";

export default {
  name: "PageHeaderClient",
  data() {
    return {
      currentClientUuid: null
    }
  },
  computed: {
    ...mapGetters('alphix', ['getClientList']),
    clientList() {
      return this.getClientList?.map(c => ({
        text: c.name,
        value: c.uuid
      }))
        ?.sort((a, b) => a?.text < b?.text ? -1 : 1) || [];
    }
  },
  watch: {
    currentClientUuid(clientUuid) {
      const client    = this.getClientList.find(c => c.uuid === clientUuid);
      const domain    = location.hostname.match(/[^.]*.(.*)/);
      window.location = `${location.protocol}//${client.subdomain}.${domain[1]}${location.port === "8084" ?
          ':8064' :
          ''}${location.pathname}`;
    }
  },
}
</script>

<style scoped>

</style>