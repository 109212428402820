<template>
  <transition
      name="slide-fade"
      mode="out-in"
  >
    <div class="sPageLeftMenu">
      <page-header-left-menu-group
        :section="{
          title: 'Delta Tag',
          links: [
            {title: 'Annual Billing Overview', routeName: 'delta-billing'}
          ]
        }"
        class="sSectionGroup"
      />
      <page-header-left-menu-group
        :section="{
          title: 'Trends',
          links: [
            {title: 'Manage Topics', routeName: 'trends-topic-management'},
            {title: 'Manage Domains', routeName: 'trends-domain-management'},
            {title: 'Manage URLs', routeName: 'trends-url-management'},
            {title: 'Manage Bookings', routeName: 'trends-booking-management'},
          ]
        }"
        class="sSectionGroup"
      />
      <page-header-left-menu-group
        :section="{
          title: 'Configure',
          links: [
            {title: 'Master Firmo Groups', routeName: 'configure-firmographic-groups'},
            {title: 'Manage Firms', routeName: 'firmographic-search'},
            {title: 'Actions', routeName: 'configure-actions-manage'}
          ]
        }"
        class="sSectionGroup"
      />
    </div>
  </transition>
</template>

<script>
import {mapGetters} from "vuex";
import PageHeaderLeftMenuGroup from "@/components/Page/Header/LeftMenu/Group";

export default {
  name: "PageHeaderLeftMenu",
  components: {PageHeaderLeftMenuGroup},
  data() {
    return {
      sectionList: []
    }
  },
};
</script>

<style scoped lang="scss">
.sPageLeftMenu {
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
  width: 234px;
  min-width: 234px;
  height: calc(100vh - 90px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 28px 0;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;

  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 5px solid #DEE2EB;
    height: 10px;

  }

  .sSectionGroup {
    &:not(:last-child):after {
      content: ' ';
      display: block;
      height: 1px;
      opacity: 0.12;
      background-color: #000000;
      margin: 12px 0;
    }
  }
}
</style>