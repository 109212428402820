import Api from "../connect/api";

const NOT_CALLED_STATE = "API~NOT_CALLED~STATE",
    LOADING_STATE = "API~LOADING~STATE";

const getDefaultState = () => {
  return {
    allowed: null,
    appList: {},
    breadcrumbList: [
      {
        text: 'Alphix Solutions',
        router: {name: "home"}
      }
    ],
    client: null,
    clientList: null,
    countryList: NOT_CALLED_STATE,
    currentApp: {},
    currentSolution: 'ALPHIX',
    settings: null,
    user: null,
    userTermsAcceptedRecently: false,
  };
};

// initial state
const state = getDefaultState();

const getters = {
  dateFormat: function () {
    if (state.user && state.user.locale === "USA") return "MM/DD/YYYY";
    return "DD/MM/YYYY";
  },
  getClientList: (state) => {
    return state.clientList;
  },
  getCountryList: () => {
    return Array.isArray(state.countryList) ? state.countryList : [];
  },
  highChartsDateFormat: function () {
    if (state.user && state.user.locale === "USA") return "%m/%d/%Y";
    return "%d/%m/%Y";
  },
  getAllowed: function () {
    return state.allowed;
  },
  getEnv() {
    if (process.env.NODE_ENV === "development") {
      return 'local';
    }

    if (window.location.host.includes("staging")) {
      return "staging"
    }

    if (window.location.host.includes("release")) {
      return "release"
    }

    if (window.location.host.includes("beta")) {
      return "beta"
    }

    if (window.location.host.includes("alpha")) {
      return "alpha"
    }

    return "production";
  },
  getSettings() {
    return state.settings;
  },
  getId: function () {
    return state.user ? state.user.id : false;
  },
  getName: function () {
    if (state.user === null) return "...";

    if (state.user === false) return "Unknown User";

    return state.user.name;
  },
  getUser: function () {
    return state.user;
  },
};

const actions = {
  lookupAllowed({commit, rootState}) {
    Api.get(
      "auth/allowed",
      response => {
        commit("setAllowed", response);
      },
      () => {
        commit("setUser", false);
      }
    );
  },
  lookupClientList({commit}) {
    Api.get("client/list",
      clientList => {
        commit("setClientList", clientList);
      }
    );
  },
  lookupCountryList({ commit, state }) {
    if(state.countryList !== NOT_CALLED_STATE) {
      return;
    }
    commit("setCountryList", LOADING_STATE);
    Api.get(
        "geo?type=country",
        response => {
          commit("setCountryList", response);
        },
        () => {
          commit("setCountryList", []);
        }
    );
  },
  lookupUser({commit}) {
    Api.get(
      "auth/current",
      response => {
        commit("setUser", response);
      },
      () => {
        commit("setUser", false);
      }
    );
  },
  lookUpSettings({commit}) {
    Api.get(
      "setting",
      response => {
        commit("setSettings", response);
      }
    )
  },
  resetState({commit}) {
    commit("resetState");
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setAllowed(state, allowed) {
    state.allowed = allowed;
  },
  setClientList(state, clientList) {
    state.clientList = clientList;
    state.client     = clientList.find(c => c.current);
  },
  setCountryList(state, countryList) {
    state.countryList = countryList;
  },
  setUser(state, user) {
    state.user = user;
  },
  setSettings(state, settings) {
    state.settings = settings;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
