<template>
  <div class="sStickyHeader">
    <transition name="slide-down">
      <header>
        <div class="logo">
          <router-link to="/">
            <img src="https://static.alphix.com/image/logo/Alphix-HOR-SOLUTIONS.svg">
          </router-link>
        </div>
        <div class="sClientSelector">
          <v-divider
            vertical
            class="mx-4"
          />
          <page-header-client />
        </div>
        <div class="menuRightButton">
          <div
            class="box"
            title="Logout"
            @click="showRightMenu = !showRightMenu"
            @mouseleave="showRightMenu = false"
          >
            <v-avatar
              color="primary"
              size="32"
            >
              <span class="white--text">{{ userInitials }}</span>
            </v-avatar>
            <transition name="fade">
              <div
                v-if="showRightMenu"
                class="menuRight"
              >
                <ul>
                  <li @click="logout()">
                    Sign out
                  </li>
                </ul>
              </div>
            </transition>
          </div>
        </div>
      </header>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Api from "../../connect/api";
import {getInitials} from '@fundamentalmedia/fundahelper/String';
import {getLoginRoute} from '@/core/domain';
import PageHeaderClient from "@/components/Page/Header/Client";

export default {
  components: {
    PageHeaderClient
  },
  data: function () {
    return {
      envs: ["development", "staging", "release", "beta", "alpha"],
      helpMenu: false,
      showMenu: false,
      showRightMenu: false
    };
  },
  computed: {
    ...mapGetters("alphix", ["getName", "getId", "getSettings",]),
    userInitials() {
      return getInitials(this.getName);
    }
  },
  created() {
    this.lookupClientList();
  },
  methods: {
    ...mapActions("alphix", ["lookupClientList"]),
    closeAppMenu() {
      this.showMenu = false;
    },
    logout() {
      this.closeAppMenu();
      Api.get("auth/logout", () => {
        window.location = getLoginRoute(false);
      });
    },
    userDetail() {
      this.$router.push({name: "user-detail"});
    }
  }
};
</script>

<style scoped lang="sass">
@import "../../scss/fundamental-variables.scss"

.sBreadcrumbTransition
  transition: margin 150ms

.sStickyHeader
  position: -webkit-sticky
  position: sticky
  top: 0
  z-index: 100

.fade-enter-active
  transition: all 200ms ease

.fade-leave-active
  transition: all 200ms ease

.fade-enter, .fade-leave-to
  opacity: 0


header
  height: 90px
  width: 100%
  display: flex
  color: #606266
  background-color: white
  border-bottom: solid 1px #DDDDDD

  .logo
    align-self: center
    padding: 30px

    img
      height: 35px

  .sClientSelector
    display: inline-flex
    align-items: center
    padding: 15px

  .menuRightButton
    display: inline-flex
    align-items: center
    margin-left: auto

    .box
      padding: 0 16px
      cursor: pointer
      height: 100%
      display: flex
      align-items: center

      &:last-child
        margin-right: 30px

      i
        font-size: 20px


    .menuRight
      position: absolute
      top: 78px
      right: 0
      z-index: 100
      background-color: #FFFFFF
      box-shadow: 0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.2)

      ul
        margin: 0
        padding: 0
        list-style: none

        li
          padding: 20px
          text-align: left
          cursor: pointer

          &:hover
            background-color: $colorWhite
</style>
